// Core
import { NavigationGuard } from "vue-router";

// Libraries
import { pinia } from "@/main";
import { until } from "@vueuse/core";

// Stores
import { useTeamStore } from "@/store/team";

const requireLeadMachine: NavigationGuard = async (_to, from, next) => {
  try {
    const teamStore = useTeamStore(pinia);

    if (!teamStore.currentTeam) {
      await until(() => teamStore.loading).changedTimes(2);
    }

    if (teamStore.teamHasLeadMachine) {
      next();
    } else {
      next(from);
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireLeadMachine;
