// Core
import router from "@/router";

// Libraries
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

// Services & Helpers
import { errorHandlerService } from "@/core/services/error";
import { handleRuntimeErrors } from "@/helpers/error-handler";
import { defaultErrorMessages } from "@/core/services/error/defaultErrors";
import { apiMultipartService, apiService } from "@/core/services/apiService";

// Composables
import { useUser } from "@/composables/useUser";
import { toast } from "@/composables/useCustomToast";
import { useTimeout } from "@/composables/useTimeout";

// Stores
import { useTeamStore } from "./team";
import { useProjectStore } from "@/store/project";

// Types
import {
  ProjectData,
  ProjectCreationState,
  UpdateProjectDataType,
  ProjectBrandVoiceType,
  Reference,
  ExtractedData,
  ReferenceResponse,
  Project,
  ProjectStepStatusT,
  FileItemT,
  ProjectDataJobT,
  KnowledgeBaseBodyT,
  KnowledgeBasesResT,
  KnowledgeBaseT,
  ProjectExtractionBodyT,
  ExtractionValidationResT,
  SourceTypeT
} from "@/@types/project";
import { User } from "@/@types/team";
import { Persona } from "@/@types/persona";
import { ITag, TagColorValue } from "@/@types/tag-input";
import { ErrorTable } from "@/core/services/error/types";
import { ApiStream } from "@/core/services/apiStreamService";

export const stringsToObjectArray = (strings?: string[] | string): { label: string; checked: boolean }[] => {
  if (Array.isArray(strings)) {
    return strings.map(str => ({ label: str, checked: true }));
  } else {
    if (strings) {
      return [{ label: strings, checked: true }];
    } else {
      return [];
    }
  }
};

export const objectArrayToStrings = (objects: { label: string; checked: boolean }[]): string[] => {
  return objects.filter(obj => obj.checked).map(obj => obj.label);
};

export const getAgeRangeFromSlider = (sliderValues: [number, number]) => {
  return `${sliderValues[0]}-${sliderValues[1]}${sliderValues[1] >= 65 && "+"}`;
};

const splitStringInTwo = (str: string, separator: string) => {
  const parts = str.split(separator, 2);

  if (parts.length < 2) return null;

  return parts;
};

export const stringToNumberArray = (str: string): [number, number] => {
  if (!str) return [25, 45];
  const parts = splitStringInTwo(str, "-");
  if (!parts?.[0] || !parts?.[1]) return [25, 45];

  const transformed = parts.map(part => {
    let numberPart = parseFloat(part);

    if (isNaN(numberPart) || !part) {
      return 25;
    }

    if (numberPart < 13) {
      numberPart = 13;
    } else if (numberPart > 65) {
      numberPart = 65;
    }

    return numberPart;
  });

  return [transformed[0] ?? 0, transformed[1] ?? 0];
};

export const useProjectCreationStore = defineStore("projectCreation", {
  state: (): ProjectCreationState => ({
    currentStep: 0,
    savedStep: 0,
    extractedDataIds: [],
    projectExtractedData: null,
    projectData: null,
    viewedSummary: false,
    disableSkipModal: false,
    isProjectCreated: false,
    showWarningModal: false,
    checkExtractionCount: 0,
    currentReferenceIndex: 0,
    hasProjectJobFailed: false,
    hasReferenceJobFailed: false,
    hasAudienceJobFailed: false,
    warningModalAction: () => {},
    files: {
      uploaded: [],
      temporary: [],
      knowledgeBase: [],
      link: "",
      title: "",
      content: "",
      valid: true,
      validTxt: "",
      materialType: "",
      validate: function (forceValue?: boolean) {
        if (forceValue !== undefined) {
          this.valid = forceValue;
        } else {
          const linkRegex = new RegExp(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?(\?.*)?$/);

          this.valid = !this.link.trim() || linkRegex.test(this.link);
          this.validTxt = this.valid ? "" : "Link inválido";
        }
      }
    },
    steps: {
      0: {
        status: "active",
        prevStatus: "completed"
      },
      1: {
        status: "disabled",
        prevStatus: "disabled"
      },
      2: {
        status: "disabled",
        prevStatus: "disabled"
      },
      3: {
        status: "disabled",
        prevStatus: "disabled"
      },
      4: {
        status: "disabled",
        prevStatus: "disabled"
      },
      5: {
        status: "disabled",
        prevStatus: "disabled"
      },
      6: {
        status: "disabled",
        prevStatus: "disabled"
      },
      7: {
        status: "disabled",
        prevStatus: "disabled"
      }
    },
    fields: {
      positiveWords: "",
      negativeWords: "",
      name: "",
      description: "",
      differentials: "",
      tones: [],
      perspectives: [],
      examples: [],
      audience: [],
      language: "Portuguese (BR)",
      banner: "",
      tags: [],
      members: [],
      cover: {
        preview: "",
        error: null
      },
      audienceEnhance: {
        interests: "",
        consciousness: "",
        characteristics: ""
      },
      audienceFields: {
        id: "",
        name: "",
        avatar: "",
        characteristics: "",
        interests: "",
        consciousnessLevel: ""
      },
      initialSummary: "",
      finalSummary: ""
    },
    linkValidationLoading: false,
    linkExtractionLoading: false,
    projectLoading: false,
    referenceLoading: false,
    linkExtractionLoadingPercent: 0,
    oldProjectname: null,
    PROJECT_NAME_STORAGE_KEY: "project-name-field"
  }),
  actions: {
    changeCurrentStep(step: number) {
      if (step === this.currentStep) return;

      this.currentStep = step;
    },
    nextStep(prevStatus: ProjectStepStatusT["status"], isProjectCreation: boolean) {
      const prevStatusObj = this.steps[this.currentStep];

      if (prevStatusObj) prevStatusObj.prevStatus = prevStatus || "disabled";

      this.goToStep(this.currentStep + 1, isProjectCreation, undefined, prevStatus);
    },
    prevStep(prevStatus: ProjectStepStatusT["status"], isProjectCreation: boolean) {
      this.goToStep(this.currentStep - 1, isProjectCreation, undefined, prevStatus);
    },
    goToStep(
      step: number,
      isProjectCreation: boolean,
      status?: ProjectStepStatusT["status"],
      prevStatus?: ProjectStepStatusT["status"]
    ) {
      if (isProjectCreation && status === "disabled") return;

      if (!isProjectCreation) {
        router.push(`/atualizar-projeto/etapa-${step + 1}`);
      }

      const prevStatusObj = this.steps[this.currentStep];

      if (prevStatusObj) prevStatusObj.status = prevStatus || prevStatusObj.prevStatus || "disabled";

      this.steps[step]!.status = "active";

      this.changeCurrentStep(step);
    },
    updateStepsStatus(
      steps: Record<number, ProjectStepStatusT>,
      stepNumber: number
    ): Record<number, ProjectStepStatusT> {
      const updatedSteps = { ...steps };

      for (const key in updatedSteps) {
        const index = parseInt(key, 10);

        if (index >= 1 && index <= 6) {
          if (index < stepNumber) {
            updatedSteps[index]!.status = "completed";
            updatedSteps[index]!.prevStatus = "completed";
          } else {
            updatedSteps[index]!.status = "disabled";
            updatedSteps[index]!.prevStatus = "disabled";
          }
        }
      }

      return updatedSteps;
    },
    addFile(fileObj: FileItemT, knowledgeBase: boolean = false) {
      if (knowledgeBase) {
        this.files.temporary = [...this.files.temporary, fileObj];
      } else {
        this.files.uploaded = [...this.files.uploaded, fileObj];
      }
    },
    removeFile(id: string, knowledgeBase: boolean = false) {
      if (knowledgeBase) {
        this.files.temporary = this.files.temporary.filter(file => file.id !== id);
        this.files.knowledgeBase = this.files.knowledgeBase.filter(file => file.id !== id);
      } else {
        this.files.uploaded = this.files.uploaded.filter(file => file.id !== id);
      }
    },
    async validateLink(
      file: FileItemT,
      teamId: string,
      disableExtraction?: { projectData?: boolean; audiences?: boolean; references?: boolean }
    ) {
      const currFile: FileItemT | undefined = this.files.uploaded.find(f => f.id === file.id);

      let formattedUrl: string = file.title;

      if (!file.title.startsWith("http://") && !file.title.startsWith("https://")) {
        formattedUrl = "https://" + file.title;
      }

      let body: { url: string; contentType: string } & ProjectExtractionBodyT = {
        url: formattedUrl,
        contentType: file.materialType
      };

      if (disableExtraction?.audiences) body.audiences = { run: false, sendNotification: false };
      if (disableExtraction?.references) body.references = { run: false, sendNotification: false };
      if (disableExtraction?.projectData) body.projectData = { run: false, sendNotification: false };

      const { error, data } = await apiService.post<ExtractionValidationResT>({
        endpoint: `teams/${teamId}/extract-data/urls`,
        body
      });

      if (error || !data) {
        if (error) {
          if (currFile) {
            currFile.error = defaultErrorMessages[error]?.message || "Link inválido";
          } else {
            toast.error({ message: defaultErrorMessages[error]?.message || "Link inválido" });
          }
        }

        return;
      }

      if (currFile) currFile.extractId = data.id;
      this.extractedDataIds = [...this.extractedDataIds, data.id];

      return data;
    },
    async validateMedia(
      file: FileItemT,
      teamId: string,
      disableExtraction?: { projectData?: boolean; audiences?: boolean; references?: boolean }
    ) {
      const currFile: FileItemT | undefined = this.files.uploaded.find(f => f.id === file.id);

      let body: { file?: File; contentType: string } & ProjectExtractionBodyT = {
        file: file.file,
        contentType: file.materialType
      };

      if (disableExtraction?.audiences) body.audiences = JSON.stringify({ run: false, sendNotification: false });
      if (disableExtraction?.references) body.references = JSON.stringify({ run: false, sendNotification: false });
      if (disableExtraction?.projectData) body.projectData = JSON.stringify({ run: false, sendNotification: false });

      const res = await apiMultipartService.post<ExtractionValidationResT>({
        endpoint: `teams/${teamId}/extract-data/audios`,
        body
      });

      if (res.error || !res.data) {
        if (res.error) {
          if (currFile) {
            if (res.errorData?.message?.includes("expected size")) {
              currFile.error = "Arquivo ultrapassa o limite de 100MB.";
            } else {
              currFile.error = defaultErrorMessages[res.error]?.message || "Arquivo inválido.";
            }
          } else {
            toast.error({ message: defaultErrorMessages[res.error]?.message || "Arquivo inválido." });
          }
        }

        return;
      }

      if (currFile) currFile.extractId = res.data.id;
      this.extractedDataIds = [...this.extractedDataIds, res.data.id];

      return res.data;
    },
    async validateFile(
      file: FileItemT,
      teamId: string,
      disableExtraction?: { projectData?: boolean; audiences?: boolean; references?: boolean }
    ) {
      const currFile: FileItemT | undefined = this.files.uploaded.find(f => f.id === file.id);

      let body: { file?: File; contentType: string } & ProjectExtractionBodyT = {
        file: file.file,
        contentType: file.materialType
      };

      if (disableExtraction?.audiences) body.audiences = JSON.stringify({ run: false, sendNotification: false });
      if (disableExtraction?.references) body.references = JSON.stringify({ run: false, sendNotification: false });
      if (disableExtraction?.projectData) body.projectData = JSON.stringify({ run: false, sendNotification: false });

      const res = await apiMultipartService.post<ExtractionValidationResT>({
        endpoint: `teams/${teamId}/extract-data/documents`,
        body
      });

      if (res.error || !res.data) {
        if (res.error) {
          if (currFile) {
            if (res.errorData?.message?.includes("expected size")) {
              currFile.error = "Arquivo ultrapassa o limite de 50MB.";
            } else {
              currFile.error = defaultErrorMessages[res.error]?.message || "Arquivo inválido.";
            }
          } else {
            toast.error({ message: defaultErrorMessages[res.error]?.message || "Arquivo inválido." });
          }
        }

        return;
      }

      if (currFile) currFile.extractId = res.data.id;
      this.extractedDataIds = [...this.extractedDataIds, res.data.id];

      return res.data;
    },
    clearCreationFields() {
      this.fields.description = "";
      this.fields.differentials = "";
      this.fields.perspectives = [];
      this.fields.tones = [];
      this.fields.positiveWords = "";
      this.fields.negativeWords = "";
      this.fields.audience = [];
    },
    async mergeProjectData(teamId: string, projectId: string) {
      const { data, error } = await apiService.post<ProjectDataJobT>({
        endpoint: `teams/${teamId}/extract-data/merge`,
        body: {
          projectId,
          extractedDatasIds: this.extractedDataIds
        }
      });

      this.linkExtractionLoading = false;

      if (!data || error) {
        this.hasProjectJobFailed = true;
        this.prevStep("disabled", true);
        toast.error({ message: "Algo deu errado ao processar os dados. Por favor, tente novamente." });
        return;
      }

      this.projectExtractedData = data;

      const { projectData, audiences, references } = data;

      this.fields.description = projectData.description || "";
      this.fields.differentials = Array.isArray(projectData.differentials)
        ? projectData.differentials.join("\n")
        : projectData.differentials || "";
      this.fields.tones = stringsToObjectArray(projectData.brandVoice?.tones || []);
      this.fields.perspectives = stringsToObjectArray(projectData.brandVoice?.perspectives || []);
      this.fields.positiveWords = Array.isArray(projectData.mustHave)
        ? projectData.mustHave.join(", ")
        : projectData.mustHave || "";
      this.fields.negativeWords = Array.isArray(projectData.mustNotHave)
        ? projectData.mustNotHave.join(", ")
        : projectData.mustNotHave || "";
      this.fields.audience = audiences.map(audience => ({ ...audience, id: uuidv4(), default: true }));
      this.addReferencesFromMerge(references);

      await this.addKnowledgeBasesToProjectData(projectId);
    },
    async verifyExtractionJob(teamId: string, id: string) {
      const { data } = await apiService.get<ExtractionValidationResT>({
        endpoint: `teams/${teamId}/extract-data/${id}`
      });

      return data;
    },
    async verifyExtractionJobs(teamId: string, ids: string[], excludeJobs?: string[]) {
      const { data } = await apiService.get<ProjectData[]>({
        endpoint: `teams/${teamId}/extract-data?ids=${ids.join(",")}`
      });

      if (!data) return { data: null, failedJobs: [] };

      const failedJobs = Object.values(data)
        .filter(item =>
          Object.entries(item.jobs).some(
            ([jobName, job]) => job && (!!excludeJobs ? !excludeJobs.includes(jobName) : true) && job.status === "errored"
          )
        )
        .map(item => item.id);

      const completed = Object.values(data).every(item =>
        Object.values(item.jobs).every(job => job && job.status !== "pending")
      );

      return { data, failedJobs, completed };
    },
    async checkResponse(teamId: string, projectId: string) {
      const { intervalIds, clearIntervalIds } = useTimeout();

      if (!this.files.uploaded.length || this.projectExtractedData) return;

      this.hasProjectJobFailed = false;
      this.linkExtractionLoading = true;

      const limit = 50;
      const intervalTime = 15000;

      const handleExtractionError = (failedJobs: string[]) => {
        clearIntervalIds();
        this.hasProjectJobFailed = true;
        this.linkExtractionLoading = false;

        failedJobs.forEach(id => {
          const currFile = this.files.uploaded.find(f => f.extractId === id);

          if (currFile) currFile.error = "Erro ao extrair dados";
        });
      };

      intervalIds.value.push(
        setInterval(async () => {
          if (this.checkExtractionCount === limit) {
            clearIntervalIds();
            return;
          }

          const { data, failedJobs, completed } = await this.verifyExtractionJobs(teamId, this.extractedDataIds, [
            "references"
          ]);

          if (!data) {
            clearIntervalIds();
            this.linkExtractionLoading = false;
            return;
          }

          if (failedJobs.length) handleExtractionError(failedJobs);

          if (completed) {
            clearIntervalIds();

            await this.mergeProjectData(teamId, projectId);
          }

          this.checkExtractionCount++;
        }, intervalTime)
      );
    },
    async createReferenceBody(
      references: Record<string, string[] | string>,
      sourceType: SourceTypeT,
      sourceName: string
    ) {
      Object.entries(references).forEach(([type, value]) => {
        if (
          !value ||
          !(value as string).length ||
          (value as string).includes("null") ||
          (Array.isArray(value) && (value as (string | null)[]).includes(null))
        )
          return;

        const tempReference: Reference = {
          id: uuidv4(),
          text: "",
          type: type,
          active: false,
          models: [type],
          checked: false,
          sourceType: sourceType,
          sourceName: sourceName,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        if (Array.isArray(value) && value.length > 0) {
          if (!["review-v2", "bullets-v2", "beneficios-v2"].includes(type)) {
            value.forEach(item => {
              this.fields.examples = [
                {
                  ...tempReference,
                  text: item || ""
                },
                ...this.fields.examples
              ];
            });
          } else {
            this.fields.examples = [
              {
                ...tempReference,
                text: value.join("\n")
              },
              ...this.fields.examples
            ];
          }
        } else if (typeof value == "string") {
          this.fields.examples = [
            {
              ...tempReference,
              text: value
            },
            ...this.fields.examples
          ];
        }
      });
    },
    async addReferencesFromExtractedData(data?: ExtractionValidationResT) {
      if (!data) return;

      this.createReferenceBody(data.jobs.references.dataExtracted, data.type as SourceTypeT, data.contentName);
    },
    async addReferencesFromMerge(data?: ProjectDataJobT["references"]) {
      if (!data) return;

      data.forEach(({ sourceType, sourceName, references }) => {
        this.createReferenceBody(references, sourceType, sourceName);
      });
    },
    async createProject(projectData: {
      name: string;
      teamId: string;
      language: string;
      users?: string[];
      coverUrl?: string;
    }) {
      const treatError = (error?: string | null) => {
        this.projectLoading = false;

        let customMessage: Partial<ErrorTable> = {
          "name-already-exists": {
            title: "Não foi possível criar o seu projeto",
            message: "Revise o campo do nome do projeto, esta informação deve ser única."
          }
        };

        if (error === "limit-reached") {
          customMessage = {
            "limit-reached": {
              title: "Não foi possível criar o seu projeto",
              message: "Você atingiu o limite de projetos."
            }
          };
        }

        errorHandlerService.handleError(error, customMessage, undefined, true);

        throw new Error(error || "Erro ao criar o projeto");
      };

      const res = await apiService.post<Project>({ endpoint: "projects", body: projectData });

      if (res.error || !res.data) {
        treatError(res.error);
      }

      this.projectData = res.data;
      this.isProjectCreated = true;
      this.oldProjectname = res.data?.name ?? null;
      res.data?.id && localStorage.setItem("projectId", res.data?.id);
      localStorage.removeItem(this.PROJECT_NAME_STORAGE_KEY);
    },
    async updateProject(projectData: UpdateProjectDataType, projectId?: string, load = true) {
      const { userStatus } = useUser();
      const idProject =
        projectId || this.projectData?.id || localStorage.getItem("projectId") || userStatus.value.lastActiveProject.id;

      if (load) this.projectLoading = true;

      const treatError = (error?: string) => {
        errorHandlerService.handleError(
          error,
          {
            "name-already-exists": {
              title: "Não foi possível alterar o seu projeto",
              message: "Revise o campo do nome do projeto, esta informação deve ser única."
            }
          },
          undefined,
          true
        );
        this.projectLoading = false;
        throw new Error(error);
      };

      if (this.oldProjectname === projectData.name) {
        this.projectLoading = false;
        return;
      }

      const res = await apiService.patch({ endpoint: `projects/${idProject}`, body: projectData });

      if (load) this.projectLoading = false;
      this.oldProjectname = this.fields.name ?? null;

      if (res.error) {
        treatError(res.error);
      }
    },
    async updateProjectResources(
      data: {
        brandVoices?: string[];
        tags?: string[];
        audiences?: string[];
        references?: string[];
      },
      projectId?: string
    ) {
      const { userStatus } = useUser();
      const idProject =
        projectId || this.projectData?.id || localStorage.getItem("projectId") || userStatus.value.lastActiveProject.id;

      const treatError = (error?: string, customMessage?: Partial<ErrorTable>) => {
        this.projectLoading = false;
        errorHandlerService.handleError(error, customMessage);
      };

      if (!idProject) {
        treatError(undefined, {
          default: {
            title: "Erro",
            message: "Não foi possível identificar o projeto. Por favor, tente novamente."
          }
        });

        return;
      }

      const res = await apiService.patch({ endpoint: `projects/${idProject}/add-resources`, body: data });

      if (res.error) {
        treatError(res.error);
      }

      return res;
    },
    async removeProjectResources(
      data: {
        brandVoices?: string[];
        tags?: string[];
        audiences?: string[];
        references?: string[];
      },
      projectId?: string
    ) {
      const idProject = projectId || this.projectData?.id;
      this.projectLoading = true;

      const treatError = (error?: string) => {
        this.projectLoading = false;
        handleRuntimeErrors(error, {
          title: "Erro!",
          message: "Algo deu errado, tente novamente."
        });
      };

      const res = await apiService.delete({ endpoint: `projects/${idProject}/remove-resources`, body: data });

      if (res.error) {
        treatError(res.error);
      }

      this.projectLoading = false;
    },
    async createProjectBrandVoice(teamId: string, brandVoiceData: ProjectBrandVoiceType, projectId?: string) {
      const idProject = projectId || this.projectData?.id;

      const treatError = (error?: string | null) => {
        this.projectLoading = false;
        error && errorHandlerService.handleError(error);
      };

      try {
        const res = await apiService.post<{ id: string }>({
          endpoint: `teams/${teamId}/brand-voices`,
          body: brandVoiceData
        });

        if (res.error || !res.data) {
          treatError(res.error);
        } else {
          await this.updateProjectResources({ brandVoices: [res.data.id] }, idProject);
        }
      } catch (e) {
        treatError(e as string);
      }
    },
    async getProjectAudiences(teamId: string, ids: string[], simplified = false) {
      this.projectLoading = true;

      const res = await apiService.get<Persona[]>({
        endpoint: `teams/${teamId}/audiences?ids=${ids.join(",")}&simplified=${simplified}`
      });

      this.projectLoading = false;

      if (res.error || !res.data) {
        res.error && errorHandlerService.handleError(res.error);
        return;
      }

      return res.data;
    },
    async createProjectAudiences(
      teamId: string,
      audienceData?: Persona,
      projectId?: string,
      audienceIds?: string[],
      loading?: boolean
    ) {
      const idProject = projectId || this.projectData?.id;
      loading && (this.projectLoading = true);

      if (audienceData) {
        try {
          let bodyData = { ...audienceData };

          const { id, gender, interests, ageRange, ...rest } = bodyData;
          id;

          bodyData = gender === "" ? (rest as Persona) : ({ gender, ...rest } as Persona);
          bodyData = interests === "" ? bodyData : { ...bodyData, interests };
          bodyData = ageRange === "" ? bodyData : { ...bodyData, ageRange };

          const res = await apiService.post<Persona>({
            endpoint: `teams/${teamId}/audiences`,
            body: bodyData
          });

          if (res.error || !res.data) {
            throw new Error();
          } else {
            return res.data.id;
          }
        } catch (e) {
          errorHandlerService.handleError(e);
          this.projectLoading = false;
          return;
        }
      } else if (audienceIds) {
        await this.updateProjectResources({ audiences: audienceIds }, idProject);
      }

      loading && (this.projectLoading = false);

      return;
    },
    async patchProjectAudiences(teamId: string, audienceId: string, body: Persona) {
      this.projectLoading = true;

      const res = await apiService.patch<Persona>({
        endpoint: `teams/${teamId}/audiences/${audienceId}`,
        body: body
      });

      this.projectLoading = false;

      if (res.error) {
        res.error && errorHandlerService.handleError(res.error, undefined, undefined, false);
        toast.error({
          title: "Não foi possível alterar a persona",
          message: "Por favor tente novamente. Se o problema persistir, entre em contato conosco."
        });
        return;
      } else {
        toast.success({
          title: "Persona alterada!",
          message: "As alterações foram salvas com sucesso."
        });
      }

      return res.data;
    },
    async deleteProjectAudiences(teamId: string, audienceId: string) {
      this.projectLoading = true;

      const res = await apiService.delete<Persona>({
        endpoint: `teams/${teamId}/audiences/${audienceId}`
      });

      this.projectLoading = false;

      if (res.error) {
        res.error && errorHandlerService.handleError(res.error);
        return;
      }

      return res.data;
    },
    async createReference(teamId: string, data: Reference) {
      try {
        const res = await apiService.post<Reference>({
          endpoint: `teams/${teamId}/references`,
          body: {
            text: data.text,
            sourceType: data.sourceType || "manual",
            sourceName: data.sourceName || "Exemplo",
            type: !Array.isArray(data.models) ? [data.models] : data.models
          }
        });

        if (res.error || !res.data) {
          if (res.errorData?.message?.includes("type must contain at least 1 elements")) {
            toast.error({
              title: "Erro ao criar exemplo(s)",
              message: "Selecione pelo menos um modelo para cada exemplo"
            });
          } else if (res.errorData?.message?.includes("text must be shorter or equal to 15000 characters")) {
            toast.error({
              title: "Erro ao criar exemplo(s)",
              message: "O texto do exemplo deve ter no máximo 15.000 caracteres"
            });
          } else {
            handleRuntimeErrors(res.errorData?.message || "Erro ao criar exemplo");
          }

          throw new Error(res.errorData?.message || "Erro ao criar exemplo");
        }

        return res;
      } catch (err) {
        throw new Error((err as string) || "Erro ao criar exemplo");
      }
    },
    async updateUsers(user: User, action: "add" | "remove" = "add", projectId?: string) {
      const { userStatus } = useUser();
      const idProject = projectId || this.projectData?.id;
      const url = `teams/${userStatus.value?.activeTeam.id}/users/${user.email}`;

      const body: Record<string, unknown> = {
        projectId: idProject,
        removeFromProject: action !== "add"
      };

      const response = await apiService.patch({ endpoint: url, body });
      return response;
    },
    async removeMember(member: User) {
      try {
        const res = await this.updateUsers(member, "remove");
        if (res.error) {
          handleRuntimeErrors(res.error);
          return;
        }
        this.fields.members = this.fields.members.filter((m: { email: string }) => m.email !== member.email);
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async addMember(member: User) {
      try {
        this.fields.members.push(member);
        const res = await this.updateUsers(member, "add");
        if (res.error) {
          handleRuntimeErrors(res.error);
          return;
        }
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async addMembers(members: User[]) {
      const { userStatus } = useUser();
      members = members.filter(member => member.email !== userStatus.value?.email);
      try {
        const reqArray = Array(members.length)
          .fill(0)
          .map(async (_, idx) => {
            const userToAdd = members[idx];
            return apiService.patch({
              endpoint: `teams/${this.projectData?.teamId}/users/${userToAdd?.email}`,
              body: {
                projectId: this.projectData?.id
              }
            });
          });

        const results = await Promise.all([...reqArray]);
        return results;
      } catch (err) {
        handleRuntimeErrors(err, {
          title: "Erro",
          message: "Um erro, inesperado ocorreu"
        });
      }
      return;
    },
    async removeTagFromTeam(teamId: string, tagId: string) {
      const projectStore = useProjectStore();
      let hasTag = false;

      for (const project of projectStore.projects) {
        project.tags.forEach(tag => {
          if ((tag as unknown as ITag).id === tagId) {
            hasTag = true;
          }
        });
        if (hasTag) break;
      }
      if (!hasTag) {
        await apiService.delete({ endpoint: `teams/${teamId}/tags/${tagId}` });
      }
    },
    async addTags(tags: ITag[], addToProject = true) {
      const { userStatus } = useUser();

      const promisesArray = tags.map(async tag => {
        // NOTE: Caso a tag já exista, apenas adiciona ao projeto
        if (tag.id && addToProject) return tag.id;

        const res = await apiService.post<{ id: string }>({
          endpoint: `teams/${userStatus.value?.activeTeam.id}/tags`,
          body: {
            color: tag.color,
            name: tag.text
          }
        });

        if (res.error || !res.data) {
          handleRuntimeErrors("Algo deu errado.");

          return;
        }

        return res.data.id;
      });

      const responses = await Promise.all(promisesArray);

      return responses;
    },
    async getTags(teamId: string, tagId?: string) {
      if (!tagId) {
        tagId = "";
      } else {
        tagId = "/" + tagId;
      }

      const res = await apiService.get({ endpoint: `teams/${teamId}/tags${tagId}` });
      return res;
    },
    async getAudiences(teamId: string, audienceId?: string) {
      const res = await apiService.get<Persona>({
        endpoint: `teams/${teamId}/audiences${audienceId && "/" + audienceId}`
      });
      return res;
    },
    async getBrandVoices(teamId: string, brandVoiceId?: string) {
      if (!brandVoiceId) return { data: null };

      const res = await apiService.get<ProjectBrandVoiceType>({
        endpoint: `teams/${teamId}/brand-voices${brandVoiceId && "/" + brandVoiceId}`
      });

      return res;
    },
    async getReferences(teamId: string, referenceId?: string) {
      const res = await apiService.get<ReferenceResponse[]>({
        endpoint: `teams/${teamId}/references${referenceId ? "/" + referenceId : ""}`
      });

      return res;
    },
    async getExtratectedData(teamId: string, extractedDataId: string) {
      const res = await apiService.get<ExtractedData>({ endpoint: `teams/${teamId}/extract-data/${extractedDataId}` });
      return res;
    },
    async updateBrandVoices(
      brandVoiceId: string,
      data: {
        tones?: string[];
        perspectives?: string[];
      },
      teamId?: string
    ) {
      const { userStatus } = useUser();
      const idTeam = teamId || userStatus.value?.activeTeam.id;

      const res = await apiService.patch({ endpoint: `teams/${idTeam}/brand-voices/${brandVoiceId}`, body: data });
      return res;
    },
    async updateReference(
      teamId: string | undefined,
      referenceId: string,
      data: { text?: string; type?: string[]; name?: string }
    ) {
      const { userStatus } = useUser();
      const idTeam = teamId || userStatus.value?.activeTeam.id;

      const res = await apiService.patch({ endpoint: `teams/${idTeam}/references/${referenceId}`, body: data });

      return res;
    },
    async updateTag(data: { color: TagColorValue; name: string }, tagId: string, teamId?: string) {
      const teamStore = useTeamStore();

      const { userStatus } = useUser();
      teamId = teamId || userStatus.value?.activeTeam.id;

      const res = await apiService.patch({ endpoint: `teams/${teamId}/tags/${tagId}`, body: data });
      if (!res.error) {
        teamStore.updateTeamTags(tagId, data);
      }

      return res;
    },
    async removeResource(
      resource: "audiences" | "brandVoices" | "references" | "tags",
      projectId: string,
      resourceIds: string[]
    ) {
      const res = await apiService.delete({
        endpoint: `projects/${projectId}/remove-resources`,
        body: {
          [resource]: resourceIds
        }
      });

      return res;
    },
    async deleteReference(teamId: string, referenceId: string) {
      return await apiService.delete({ endpoint: `teams/${teamId}/references/${referenceId}` });
    },
    async getKnowledgeBases(projectId: string, page = 1, amount = 10) {
      const { data, error } = await apiService.get<KnowledgeBasesResT>({
        endpoint: `knowledge-bases?projectId=${projectId}&page=${page}&amount=${amount}`
      });

      if (!data || error) return;

      return data;
    },
    async createKnowledgeBase(body: KnowledgeBaseBodyT) {
      const { data, error } = await apiService.post<KnowledgeBaseT>({ endpoint: "knowledge-bases", body });

      if (!data || error) {
        toast.error({
          message: "Não foi possível criar a base de conhecimento"
        });

        return;
      }

      return data;
    },
    async updateKnowledgeBase(knowledgeBaseId: string, body: { contentType: string; name: string }) {
      const { error } = await apiService.patch({
        endpoint: `knowledge-bases/${knowledgeBaseId}`,
        body
      });

      if (error) {
        toast.error({
          message: "Não foi possível atualizar a base de conhecimento"
        });
      }
    },
    async deleteKnowledgeBase(knowledgeBaseId: string) {
      const { error } = await apiService.delete({ endpoint: `knowledge-bases/${knowledgeBaseId}` });

      if (error) {
        toast.error({
          message: "Não foi possível deletar a base de conhecimento"
        });
      }
    },
    async addKnowledgeBasesToProjectData(projectId: string) {
      const res = await this.getKnowledgeBases(projectId);

      if (res?.knowledgeBases && this.projectData) {
        this.projectData.knowledgeBases = res;

        this.files.uploaded = this.files.uploaded.map(file => {
          const kb = res.knowledgeBases?.find(kb => kb.source.includes(file.file ? file.file.name : file.title));

          if (!kb) return file;

          return {
            ...file,
            id: kb.id,
            name: kb.name,
            text: kb.text,
            source: kb.source
          };
        });
      }
    },
    async updateSummary(projectId: string, type: "initial" | "final", isStream: boolean = false) {
      const endpoint = `projects/${projectId}/summary`;

      const { tones, perspectives, positiveWords, negativeWords, description, differentials } = this.fields;

      const brandVoice = {
        ...(tones.length > 0 && { tones: tones.map(tone => tone.label) }),
        ...(perspectives.length > 0 && { perspectives: perspectives.map(p => p.label) })
      };

      const body: {
        type: "initial" | "final";
        mustHave?: string;
        description?: string;
        mustNotHave?: string;
        differentials?: string;
        brandVoice?: {
          tones?: string[];
          perspectives?: string[];
        };
      } = {
        type,
        ...(Object.keys(brandVoice).length > 0 && { brandVoice }),
        ...(positiveWords && { mustHave: positiveWords }),
        ...(negativeWords && { mustNotHave: negativeWords }),
        ...(description && { description }),
        ...(differentials && { differentials })
      };

      if (isStream) {
        const stream = new ApiStream("POST", endpoint, { stream: true, ...body });

        stream
          .onData(({ value }) => {
            if (value.includes("DONE")) return;

            if (type === "initial") {
              this.fields.initialSummary = this.fields.initialSummary + value;
            } else {
              this.fields.finalSummary = this.fields.finalSummary + value;
            }
          })
          .onError(err => {
            handleRuntimeErrors(err);
          })
          .start();
      } else {
        const { data, error } = await apiService.post<{ generatedText: string }>({
          endpoint,
          body
        });

        if (!data || error) return;

        if (type === "initial") {
          this.fields.initialSummary = data.generatedText;
        } else {
          this.fields.finalSummary = data.generatedText;
        }
      }
    }
  }
});
